import {Component}                          from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AuthService}                        from "../../services/auth.service";
import {ToastService}                       from "../../../shared/services/toast.service";
import {map, Observable, takeWhile, timer}  from "rxjs";

@Component({
    selector: 'app-verify-email',
    templateUrl: './verify-email.component.html',
    styleUrls: ['../login/login.component.scss', '../../../../assets/scss/circles.scss']
})
export class VerifyEmailComponent
{
    public verifyEmailForm: FormGroup = new FormGroup('');
    public error: any;
    public timeRemaining$: Observable<number>;

    public constructor(
        private fb: FormBuilder,
        private authService: AuthService,
        private toastService: ToastService,
    )
    {
    }

    public ngOnInit(): void
    {
        this.verifyEmailForm = this.fb.group({
            email: [null, [Validators.required, Validators.email]],
        });

        this.verifyEmailForm.disable();

        this.timeRemaining$ = timer(0, 1000).pipe(
            map(n => (30 - n) * 1000),
            takeWhile(n => n >= 0),
        );

        setTimeout(() => this.verifyEmailForm.enable(), 30000);
    }

    public onSubmit(): void
    {
        if (this.verifyEmailForm.valid) {
            this.verifyEmailForm.disable();

            this.authService.verifyEmail(this.verifyEmailForm.value).subscribe({
                next: () => this.toastService.success('An email has been sent to your address, please check your junk folder.'),
                error: (error) => {
                    console.log(error);
                    this.toastService.success('An email has been sent to your address, please check your junk folder.');
                }
            });

            this.verifyEmailForm.reset();

            this.timeRemaining$ = timer(0, 1000).pipe(
                map(n => (30 - n) * 1000),
                takeWhile(n => n >= 0),
            );

            setTimeout(() => this.verifyEmailForm.enable(), 30000);
        }
    }
}
