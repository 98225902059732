import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
} from '@angular/router';
import {AuthService} from "../services/auth.service";
import { firstValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard {
    constructor(
        private authService: AuthService,
        private router: Router
    ) {
    }

    public async canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<boolean> {
        let test = await this.check(state.url);
        return await this.check(state.url);
    }

    public async canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<boolean> {
        return this.check(state.url);
    }

    public async canLoad(
        route: Route,
        segments: UrlSegment[]
    ): Promise<boolean> {
        return this.check('/');
    }

    private async check(redirectUrl: string): Promise<boolean> {
        console.log('%c AuthGuard check...', 'color: orange;');

        if (this.authService.user?.id && !this.authService.isAuthenticated() && this.authService.canRefreshToken()) {
            await firstValueFrom(this.authService.refresh());
        }

        if (!this.authService.isAuthenticated()) {
            await this.router.navigate(['/login'], {queryParams: {redirectUrl}});
        }

        return this.authService.isAuthenticated();
    }
}
