<div class="authentication-header">
    <ul class="circles">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
    </ul>
</div>

<div class="login-panel d-flex align-items-center justify-content-center">
    <div class="card p-4 w-25">
        <div class="card-body pt-0">
            <div class="text-center">
                <img alt="logo" src="assets/images/MTALogoNoBackground-01.png" class="logo" />

                <div class="my-4">
                    <h5 class="card-title">Sign in</h5>
                    <p class="card-text">Don't have an account yet? <a routerLink="/register">Sign up here</a></p>
                </div>
            </div>

            <form #ngForm="ngForm" (ngSubmit)="onSubmit()" [formGroup]="loginForm" class="row g-3">
                <div class="mb-3">
                    <label class="form-label" for="email">Email</label>
                    <input class="form-control" formControlName="email" id="email" autocomplete="username" placeholder="Email Address" type="email">
                    <div *ngIf="loginForm.get('email')?.invalid && (loginForm.get('email')?.dirty || loginForm.get('email')?.touched)" class="invalid-feedback">
                        <ng-container *ngIf="loginForm.get('email')?.errors?.['required']">Please provide an email address.</ng-container>
                        <ng-container *ngIf="loginForm.get('email')?.errors?.['pattern']">Please provide a valid email address.</ng-container>
                    </div>
                </div>

                <div class="mb-3">
                    <label class="form-label" for="password">Password</label>
                    <div class="input-group">
                        <input class="form-control" formControlName="password" id="password" autocomplete="current-password" placeholder="Enter Password" [type]="revealPassword ? 'text' : 'password'" value="12345678">
                        <a class="input-group-text" (click)="revealPassword = !revealPassword;"><i [class]="revealPassword ? 'fa-solid fa-eye-slash' : 'fa-solid fa-eye'"></i></a>
                    </div>
                </div>

                <div class="d-grid gap-2">
                    <button type="submit" class="btn btn-primary"><i class="bx bxs-lock-open"></i>Sign in</button>
                </div>

                <div class="col-12 col-md-6">
                    <div class="form-check form-switch">
                        <input checked class="form-check-input" id="flexSwitchCheckChecked" type="checkbox">
                        <label class="form-check-label" for="flexSwitchCheckChecked">Remember Me</label>
                    </div>
                </div>

                <div class="col-12 col-md-6 text-end">
                    <a routerLink="/forgot-password">Forgot Password?</a>
                </div>
            </form>
        </div>
    </div>
</div>
