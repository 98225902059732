import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {AuthService} from "../../services/auth.service";
import {ToastService} from "../../../shared/services/toast.service";
import {HttpErrorResponse} from "@angular/common/http";
import {FormService} from "../../../shared/services/form.service";
import {confirmPassword, passwordStrength} from "../../../validators/password-confirmation.validator";
import {DropzoneConfigInterface} from "ngx-dropzone-wrapper";
import {requiredWithout} from "../../../validators/required-without.validator";
import {IntegrationTypes} from "../../../models/enums/integration-types.enum";
import {Subject, takeUntil} from "rxjs";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss', '../../../../assets/scss/circles.scss']
})
export class RegisterComponent implements OnInit, OnDestroy {
    public registerForm: FormGroup;
    public submitting: boolean = false;
    public activeTab: string = 'userDetails';
    public dropzoneConfig: DropzoneConfigInterface;
    protected unsubscribe: Subject<void> = new Subject<void>();
    protected readonly IntegrationTypes = IntegrationTypes;
    public tabs: string[] = ['userDetails', 'organisationDetails', 'addressDetails', 'integrationDetails'];
    private formGroupMappings = {
        userDetails: () => this.user,
        organisationDetails: () => this.organisation,
        addressDetails: () => this.addresses,
        integrationDetails: () => this.integrations
    };

    public get user(): FormGroup
    {
        return this.registerForm.get('user') as FormGroup;
    }

    public get organisation(): FormGroup
    {
        return this.registerForm.get('organisation') as FormGroup;
    }

    public get addresses(): FormGroup
    {
        return this.registerForm.get('addresses') as FormGroup;
    }

    public get integrations(): FormGroup
    {
        return this.registerForm.get('integrations') as FormGroup;
    }

    constructor(
        public formService: FormService,
        private router: Router,
        private fb: FormBuilder,
        private authService: AuthService,
        private toastService: ToastService,
        private cdr: ChangeDetectorRef
    ) {
    }

    public ngOnInit(): void {
        this.registerForm = this.fb.group({
            user: this.fb.group({
                name: [null, Validators.required],
                email: [null, [Validators.required, Validators.email]],
                password: [null, [Validators.required, passwordStrength()]],
                password_confirmation: [null, Validators.required],
            }),
            organisation: this.fb.group({
                name: [null, Validators.required],
                logo: [null],
                email: [null, [Validators.required, Validators.email]],
                mobile: [null],
                phone: [null],
                website: [null],
                vat_number: [null],
                company_number: [null],
            }, { validators: requiredWithout('phone', 'mobile') }),
            addresses: this.fb.group({
                correspondence: this.fb.group({
                    address_1: [null, Validators.required],
                    address_2: [null],
                    address_3: [null],
                    city: [null, Validators.required],
                    county: [null],
                    country: [null, Validators.required],
                    postcode: [null, Validators.required],
                }),
                trading: this.fb.group({
                    address_1: [null, Validators.required],
                    address_2: [null],
                    address_3: [null],
                    city: [null, Validators.required],
                    county: [null],
                    country: [null, Validators.required],
                    postcode: [null, Validators.required],
                })
            }),
            integrations: this.fb.group({
                account_id: [null, [Validators.required]],
            }),
            frontend_url: [`${environment.localPath}/confirm-email`, Validators.required],
        }, {validators: confirmPassword('user.password', 'user.password_confirmation')});

        this.addresses.get('trading')?.valueChanges
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(val => {
                if (this.formService.usingSameAddress) {
                    this.addresses.get('correspondence')?.setValue(val, { emitEvent: false });
                }

                this.cdr.detectChanges();
            });

        this.dropzoneConfig = {
            paramName: 'logo',
            maxFiles: 1,
            createImageThumbnails: false,
            autoReset: 1,
        };
    }

    public onSubmit(): void {
        this.formService.submitted = true;
        this.submitting = true;

        this.registerForm.disable();

        if (this.formService.usingSameAddress) {
            // TODO: Make this a function?
            this.registerForm.get('addresses.correspondence')?.patchValue({
                address_1: this.registerForm.get('addresses.trading.address_1')?.value,
                address_2: this.registerForm.get('addresses.trading.address_2')?.value,
                address_3: this.registerForm.get('addresses.trading.address_3')?.value,
                city: this.registerForm.get('addresses.trading.city')?.value,
                county: this.registerForm.get('addresses.trading.county')?.value,
                country: this.registerForm.get('addresses.trading.country')?.value,
                postcode: this.registerForm.get('addresses.trading.postcode')?.value,
            });
        }

        this.authService.register(this.registerForm.value).subscribe({
            next: (response) => {
                this.authService.authenticateUser(response);
                this.toastService.success('Please confirm your email address.', 'Registered successfully.');
                this.router.navigateByUrl('/');
            },
            error: (response: HttpErrorResponse) => {
                this.submitting = false;

                this.setTab('userDetails');
                this.authService.handleError(response, this.registerForm);
            }
        });
    }

    public currentTabIsValid(tab?: string): boolean {
        const tabFormGroup = this.formGroupMappings[tab ?? this.activeTab];
        return this.formService.submitted || tabFormGroup().valid;
    }

    public setTab(tab: string) {
        if (this.canChangeToTab(tab)) {
            this.activeTab = tab;
        }
    }

    public canChangeToTab(tab: string): boolean {
        const currentIndex: number = this.tabs.indexOf(this.activeTab);
        const targetIndex: number = this.tabs.indexOf(tab);
        return targetIndex > currentIndex ? this.currentTabIsValid() : true;
    }

    public previousTab(): void {
        const currentIndex: number = this.tabs.indexOf(this.activeTab);

        if (currentIndex > 0) {
            const previousTab = this.tabs[currentIndex - 1];
            this.setTab(previousTab);
        }
    }

    public nextTab(): void {
        const currentIndex: number = this.tabs.indexOf(this.activeTab);

        if (currentIndex < this.tabs.length - 1) {
            const nextTab = this.tabs[currentIndex + 1];
            this.setTab(nextTab);
        }
    }

    public mirrorToCorrespondence(): void {
        if (!this.formService.usingSameAddress) {
            return;
        }

        const correspondenceAddressControl = this.registerForm.get('addresses.correspondence');
        const tradingAddressControl = this.registerForm.get('addresses.trading');
        if (correspondenceAddressControl && tradingAddressControl) {
            correspondenceAddressControl.patchValue(tradingAddressControl.value);
        }
    }

    public ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
