import {FormGroup} from '@angular/forms';

export function requiredWithout(...controlNames: string[]) {
    return (form: FormGroup) => {
        let controlsEmpty: boolean = controlNames.every((name: string): boolean => {
            const control = form.get(name);
            return control ? !control.value : true;
        });

        const updateErrors = (error: null | { required: boolean }) => {
            controlNames.forEach(name => {
                const control = form.get(name);
                if (control) {
                    control.setErrors(error);
                }
            });
        }

        // If all the other controls are empty, set an error
        if (controlsEmpty) {
            updateErrors({ required: true });
        } else {
            // Otherwise remove the error
            updateErrors(null);
        }

        return null;
    };
}
