<div class="authentication-header">
    <ul class="circles">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
    </ul>
</div>

<div class="login-panel d-flex align-items-center justify-content-center">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 col-lg-10 offset-lg-1 flex register-card">
                <div class="card h-100 mb-0 rounded-4">
                    <div class="card-header border-0">
                        <div class="row">
                            <div class="col-12 col-lg-6 p-4 text-center text-lg-start">
                                <h3 class="">Register</h3>
                                <p>Already have an account? <a routerLink="/login">Sign in here</a></p>
                            </div>

                            <div class="col-12 col-lg-6 text-center text-lg-end">
                                <img src="assets/images/MTALogoNoBackground-01.png" alt="logo" class="logo" />
                            </div>
                        </div>

                        <div class="bs-stepper px-4">
                            <div class="d-lg-flex flex-lg-row align-items-lg-center justify-content-lg-between" role="tablist">
                                <div class="step" data-target="#userDetails" [class.active]="activeTab === 'userDetails'">
                                    <div class="step-trigger" role="tab" id="stepper2trigger1" aria-controls="userDetails" aria-selected="true" (click)="setTab('userDetails')">
                                        <span class="bs-stepper-circle">
                                            <i class="fa-solid fa-user"></i>
                                        </span>

                                        <div>
                                            <h5 class="mb-0 steper-title">User</h5>
                                            <p class="mb-0 steper-sub-title">Enter your details</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="bs-stepper-line"></div>

                                <div class="step" data-target="#organisationDetails" [class.active]="activeTab === 'organisationDetails'">
                                    <div class="step-trigger" role="tab" id="stepper2trigger2" aria-controls="organisationDetails" aria-selected="false" [class.disabled]="!currentTabIsValid('userDetails')" (click)="setTab('organisationDetails')">
                                        <span class="bs-stepper-circle">
                                            <i class="fa-solid fa-building"></i>
                                        </span>

                                        <div class="">
                                            <h5 class="mb-0 steper-title">Organisation</h5>
                                            <p class="mb-0 steper-sub-title">Setup business details</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="bs-stepper-line"></div>

                                <div class="step" data-target="#addressDetails" [class.active]="activeTab === 'addressDetails'">
                                    <div class="step-trigger" role="tab" id="stepper2trigger3" aria-controls="addressDetails" aria-selected="false" [class.disabled]="!currentTabIsValid('organisationDetails')" (click)="setTab('addressDetails')">
                                        <span class="bs-stepper-circle">
                                            <i class="fa-solid fa-location-dot"></i>
                                        </span>

                                        <div class="">
                                            <h5 class="mb-0 steper-title">Addresses</h5>
                                            <p class="mb-0 steper-sub-title">Your business addresses</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="bs-stepper-line"></div>

                                <div class="step" data-target="#integrationDetails" [class.active]="activeTab === 'integrationDetails'">
                                    <div class="step-trigger" role="tab" id="stepper2trigger4" aria-controls="integrationDetails" aria-selected="false" [class.disabled]="!currentTabIsValid('addressDetails')" (click)="setTab('integrationDetails')">
                                        <span class="bs-stepper-circle">
                                            <i class="fa-solid fa-diagram-project"></i>
                                        </span>

                                        <div class="">
                                            <h5 class="mb-0 steper-title">Integrations</h5>
                                            <p class="mb-0 steper-sub-title">Connect your system</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card-body px-4" [formGroup]="registerForm">
                        <div class="bs-stepper-content">
                            <div id="userDetails" role="tabpanel" class="bs-stepper-pane" [formGroup]="user" [ngClass]="{'active' : activeTab === 'userDetails'}">
                                <h5 class="mb-1">User Details</h5>
                                <p class="mb-4">Add your user details here.</p>

                                <div class="mb-3">
                                    <label for="name" class="form-label required">Name</label>
                                    <input type="text" class="form-control" id="name" placeholder="John" formControlName="name" [class.is-invalid]="formService.isInvalid(registerForm, 'user.name')" />

                                    <div class="invalid-feedback" *ngIf="formService.isInvalid(registerForm, 'user.name', 'required')">
                                        Please add your name.
                                    </div>

                                    <div class="invalid-feedback" *ngIf="formService.isInvalid(registerForm, 'user.name', 'error')">
                                        @for (errorMessage of registerForm.get('user.name')?.errors?.['error'] ?? {}; track errorMessage) {
                                            {{ errorMessage }}<br />
                                        }
                                    </div>
                                </div>

                                <div class="mb-3">
                                    <label for="email" class="form-label required">Email Address</label>
                                    <input type="email" class="form-control" id="email" placeholder="example@user.com" formControlName="email" [class.is-invalid]="formService.isInvalid(registerForm, 'user.email')" />

                                    <div class="invalid-feedback" *ngIf="formService.isInvalid(registerForm, 'user.email', 'required')">
                                        Please provide an email address.
                                    </div>

                                    <div class="invalid-feedback" *ngIf="formService.isInvalid(registerForm, 'user.email', 'email')">
                                        Please provide a valid email address.
                                    </div>

                                    <div class="invalid-feedback" *ngIf="formService.isInvalid(registerForm, 'user.email', 'error')">
                                        @for (errorMessage of registerForm.get('user.email')?.errors?.['error'] ?? {}; track errorMessage) {
                                            {{ errorMessage }}<br />
                                        }
                                    </div>
                                </div>

                                <div class="mb-3">
                                    <label for="password" class="form-label required">Password</label>
                                    <input type="password" class="form-control" id="password" placeholder="Enter Password" autocomplete="new-password" formControlName="password" [class.is-invalid]="formService.isInvalid(registerForm, 'user.password')" />

                                    <div class="invalid-feedback" *ngIf="formService.isInvalid(registerForm, 'user.password', 'passwordStrength')">
                                        <ng-container *ngIf="user.get('password')?.errors?.['required']">Please enter a new password. <br /></ng-container>
                                        <ng-container *ngIf="user.get('password')?.errors?.['passwordStrength']['uppercase']">{{ user.get('password')?.errors?.['passwordStrength']['uppercase'] }} <br /></ng-container>
                                        <ng-container *ngIf="user.get('password')?.errors?.['passwordStrength']['lowercase']">{{ user.get('password')?.errors?.['passwordStrength']['lowercase'] }} <br /></ng-container>
                                        <ng-container *ngIf="user.get('password')?.errors?.['passwordStrength']['numerical']">{{ user.get('password')?.errors?.['passwordStrength']['numerical'] }} <br /></ng-container>
                                        <ng-container *ngIf="user.get('password')?.errors?.['passwordStrength']['special']">{{ user.get('password')?.errors?.['passwordStrength']['special'] }} <br /></ng-container>
                                        <ng-container *ngIf="user.get('password')?.errors?.['passwordStrength']['length']">{{ user.get('password')?.errors?.['passwordStrength']['length'] }}</ng-container>
                                    </div>

                                    <div class="invalid-feedback" *ngIf="formService.isInvalid(registerForm, 'user.password', 'error')">
                                        @for (errorMessage of registerForm.get('user.password')?.errors?.['error'] ?? {}; track errorMessage) {
                                            {{ errorMessage }}<br />
                                        }
                                    </div>
                                </div>

                                <div class="mb-3">
                                    <label for="password_confirmation" class="form-label required">Confirm Password</label>
                                    <input type="password" class="form-control" id="password_confirmation" placeholder="Confirm Password" autocomplete="new-password" formControlName="password_confirmation" [class.is-invalid]="formService.isInvalid(registerForm)" />

                                    <div class="invalid-feedback" *ngIf="formService.isInvalid(registerForm)">
                                        <ng-container *ngIf="registerForm.errors?.['passwordsUnconfirmed']">Passwords do not match.</ng-container>
                                    </div>
                                </div>
                            </div>

                            <div id="organisationDetails" role="tabpanel" class="bs-stepper-pane" [formGroup]="organisation" [ngClass]="{'active' : activeTab === 'organisationDetails'}">
                                <h5 class="mb-1">Organisation Details</h5>
                                <p class="mb-4">Add the details for your organisation here.</p>

                                <div class="mb-3">
                                    <label for="organisation-name" class="form-label required">Name</label>
                                    <input type="text" class="form-control" id="organisation-name" placeholder="Acme Ltd" autocomplete="organization" formControlName="name" [class.is-invalid]="formService.isInvalid(registerForm, 'organisation.name')" />

                                    <div class="invalid-feedback" *ngIf="formService.isInvalid(registerForm, 'organisation.name', 'required')">
                                        Please add the organisation name.
                                    </div>

                                    <div class="invalid-feedback" *ngIf="formService.isInvalid(registerForm, 'organisation.name', 'error')">
                                        @for (errorMessage of registerForm.get('organisation.name')?.errors?.['error'] ?? {}; track errorMessage) {
                                            {{ errorMessage }}<br />
                                        }
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-12 col-md-6">
                                        <div class="mb-3">
                                            <label for="organisation-mobile" class="form-label" [class.required]="formService.isInvalid(registerForm, 'organisation.mobile') && formService.isInvalid(registerForm, 'organisation.phone')">Mobile</label>
                                            <input type="text" class="form-control" id="organisation-mobile" placeholder="071234567" formControlName="mobile" [class.is-invalid]="formService.isInvalid(registerForm, 'organisation.mobile') && formService.isInvalid(registerForm, 'organisation.phone')" />

                                            <div class="invalid-feedback" *ngIf="formService.isInvalid(registerForm, 'organisation.mobile', 'required') && formService.isInvalid(registerForm, 'organisation.phone', 'required')">
                                                Please provide either a phone or a mobile number.
                                            </div>

                                            <div class="invalid-feedback" *ngIf="formService.isInvalid(registerForm, 'organisation.mobile', 'error')">
                                                @for (errorMessage of registerForm.get('organisation.mobile')?.errors?.['error'] ?? {}; track errorMessage) {
                                                    {{ errorMessage }}<br />
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12 col-md-6">
                                        <div class="mb-3">
                                            <label for="organisation-phone" class="form-label" [class.required]="formService.isInvalid(registerForm, 'organisation.mobile') && formService.isInvalid(registerForm, 'organisation.phone')">Phone</label>
                                            <input type="text" class="form-control" id="organisation-phone" placeholder="01482 123 456" formControlName="phone" [class.is-invalid]="formService.isInvalid(registerForm, 'organisation.mobile') && formService.isInvalid(registerForm, 'organisation.phone')" />

                                            <div class="invalid-feedback" *ngIf="formService.isInvalid(registerForm, 'organisation.phone', 'error')">
                                                @for (errorMessage of registerForm.get('organisation.phone')?.errors?.['error'] ?? {}; track errorMessage) {
                                                    {{ errorMessage }}<br />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-12 col-md-6">
                                        <div class="mb-3">
                                            <label for="organisation-email" class="form-label required">Email</label>
                                            <input type="email" class="form-control" id="organisation-email" placeholder="accounts@acmeltd.co.uk" formControlName="email" [class.is-invalid]="formService.isInvalid(registerForm, 'organisation.name')" />

                                            <div class="invalid-feedback" *ngIf="formService.isInvalid(registerForm, 'organisation.email', 'required')">
                                                Please add an email address.
                                            </div>

                                            <div class="invalid-feedback" *ngIf="formService.isInvalid(registerForm, 'organisation.email', 'email')">
                                                Please add a valid email address.
                                            </div>

                                            <div class="invalid-feedback" *ngIf="formService.isInvalid(registerForm, 'organisation.email', 'error')">
                                                @for (errorMessage of registerForm.get('organisation.email')?.errors?.['error'] ?? {}; track errorMessage) {
                                                    {{ errorMessage }}<br />
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12 col-md-6">
                                        <div class="mb-3">
                                            <label for="organisation-website" class="form-label">Website</label>
                                            <input type="text" class="form-control" id="organisation-website" placeholder="acmeltd.co.uk" formControlName="website" />

                                            <div class="invalid-feedback" *ngIf="formService.isInvalid(registerForm, 'organisation.website', 'error')">
                                                @for (errorMessage of registerForm.get('organisation.website')?.errors?.['error'] ?? {}; track errorMessage) {
                                                    {{ errorMessage }}<br />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-12 col-lg-6">
                                        <div class="mb-3">
                                            <label for="organisation-vat" class="form-label">VAT Number</label>

                                            <div class="input-group">
                                                <span class="input-group-text">GB</span>
                                                <input type="text" class="form-control" id="organisation-vat" placeholder="123 4567 89" formControlName="vat_number" />

                                                <div class="invalid-feedback" *ngIf="formService.isInvalid(registerForm, 'organisation.vat_number', 'error')">
                                                    @for (errorMessage of registerForm.get('organisation.vat_number')?.errors?.['error'] ?? {}; track errorMessage) {
                                                        {{ errorMessage }}<br />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12 col-lg-6">
                                        <div class="mb-3">
                                            <label for="organisation-company-number" class="form-label">Company Number</label>
                                            <input type="text" class="form-control" id="organisation-company-number" placeholder="12345678" formControlName="company_number" />

                                            <div class="invalid-feedback" *ngIf="formService.isInvalid(registerForm, 'organisation.company_number', 'error')">
                                                @for (errorMessage of registerForm.get('organisation.company_number')?.errors?.['error'] ?? {}; track errorMessage) {
                                                    {{ errorMessage }}<br />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="mb-3">
                                        <label class="form-label">Company Logo</label>
                                        <dropzone [config]="dropzoneConfig" [message]="'Click or drag images here to upload'"></dropzone>
                                    </div>
                            </div>

                            <div id="addressDetails" role="tabpanel" class="bs-stepper-pane" [formGroup]="addresses" [ngClass]="{'active' : activeTab === 'addressDetails'}">
                                <h5 class="mb-1">Addresses</h5>
                                <p class="mb-4">Specify your trading and correspondence addresses here.</p>

                                <div class="row">
                                    <div class="col-12 col-lg-6" formGroupName="trading">
                                        <div class="card shadow-none border mb-3">
                                            <div class="card-body">
                                                <h5 class="card-title mb-4 fw-bold">Trading Address</h5>

                                                <div class="mb-3">
                                                    <label class="form-label required">Address Line 1</label>
                                                    <input class="form-control" type="text" formControlName="address_1" autocomplete="shipping address-line1" [class.is-invalid]="formService.isInvalid(registerForm, 'addresses.trading.address_1')" />
                                                    <div class="invalid-feedback" *ngIf="formService.isInvalid(registerForm, 'addresses.trading.address_1', 'required')">Please provide the first address line.</div>

                                                    <div class="invalid-feedback" *ngIf="formService.isInvalid(registerForm, 'addresses.trading.address_1', 'error')">
                                                        @for (errorMessage of registerForm.get('addresses.trading.address_1')?.errors?.['error'] ?? {}; track errorMessage) {
                                                            {{ errorMessage }}<br />
                                                        }
                                                    </div>
                                                </div>

                                                <div class="mb-3">
                                                    <label class="form-label">Address Line 2</label>
                                                    <input class="form-control" type="text" formControlName="address_2" autocomplete="shipping address-line2" />
                                                </div>

                                                <div class="mb-3">
                                                    <label class="form-label">Address Line 2</label>
                                                    <input class="form-control" type="text" formControlName="address_3" autocomplete="shipping address-line3" />
                                                </div>

                                                <div class="row">
                                                    <div class="col-12 col-xxl-6">
                                                        <div class="mb-3">
                                                            <label class="form-label required">City</label>
                                                            <input class="form-control" type="text" autocomplete="shipping address-level2" formControlName="city" [class.is-invalid]="formService.isInvalid(registerForm, 'addresses.trading.city')" />
                                                            <div class="invalid-feedback" *ngIf="formService.isInvalid(registerForm, 'addresses.trading.city', 'required')">Please provide a locality.</div>

                                                            <div class="invalid-feedback" *ngIf="formService.isInvalid(registerForm, 'addresses.trading.city', 'error')">
                                                                @for (errorMessage of registerForm.get('addresses.trading.city')?.errors?.['error'] ?? {}; track errorMessage) {
                                                                    {{ errorMessage }}<br />
                                                                }
                                                            </div>
                                                        </div>

                                                        <div class="mb-3">
                                                            <label class="form-label">County</label>
                                                            <input class="form-control" type="text" formControlName="county" />
                                                        </div>
                                                    </div>

                                                    <div class="col-12 col-xxl-6">
                                                        <div class="mb-3">
                                                            <label class="form-label required">Country</label>
                                                            <input class="form-control" type="text" formControlName="country" autocomplete="shipping country-name" [class.is-invalid]="formService.isInvalid(registerForm, 'addresses.trading.country')" />
                                                            <div class="invalid-feedback" *ngIf="formService.isInvalid(registerForm, 'addresses.trading.country', 'required')">Please provide a country.</div>

                                                            <div class="invalid-feedback" *ngIf="formService.isInvalid(registerForm, 'addresses.trading.country', 'error')">
                                                                @for (errorMessage of registerForm.get('addresses.trading.country')?.errors?.['error'] ?? {}; track errorMessage) {
                                                                    {{ errorMessage }}<br />
                                                                }
                                                            </div>
                                                        </div>

                                                        <div class="mb-3">
                                                            <label class="form-label required">Postcode</label>
                                                            <input class="form-control" type="text" formControlName="postcode" autocomplete="shipping postal-code" [class.is-invalid]="formService.isInvalid(registerForm, 'addresses.trading.postcode')" />
                                                            <div class="invalid-feedback" *ngIf="formService.isInvalid(registerForm, 'addresses.trading.postcode', 'required')">Please provide a postcode.</div>

                                                            <div class="invalid-feedback" *ngIf="formService.isInvalid(registerForm, 'addresses.trading.postcode', 'error')">
                                                                @for (errorMessage of registerForm.get('addresses.trading.postcode')?.errors?.['error'] ?? {}; track errorMessage) {
                                                                    {{ errorMessage }}<br />
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                         </div>
                                    </div>

                                    <div class="col-12 col-lg-6" formGroupName="correspondence">
                                         <div class="card shadow-none border mb-3">
                                            <div class="card-body">
                                                <h5 class="card-title mb-4 fw-bold">Correspondence Address</h5>

                                               <div class="col-12">
                                                    <div class="mb-3">
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox" id="flexCheckChecked" [checked]="formService.usingSameAddress" (change)="formService.toggleSameAddress(registerForm, 'addresses.trading', 'addresses.correspondence')" />
                                                            <label class="form-check-label" for="flexCheckChecked">Same as Trading address?</label>
                                                        </div>
                                                    </div>
                                               </div>

                                                <ng-container *ngIf="!formService.usingSameAddress">
                                                    <div class="mb-3">
                                                        <label class="form-label required">Address Line 1</label>
                                                        <input class="form-control" type="text" formControlName="address_1" autocomplete="billing address-line1" [class.is-invalid]="formService.isInvalid(registerForm, 'addresses.correspondence.address_1')" />
                                                        <div class="invalid-feedback" *ngIf="formService.isInvalid(registerForm, 'addresses.correspondence.address_1', 'required')">Please provide the first address line.</div>

                                                        <div class="invalid-feedback" *ngIf="formService.isInvalid(registerForm, 'addresses.correspondence.address_1', 'error')">
                                                            @for (errorMessage of registerForm.get('addresses.correspondence.address_1')?.errors?.['error'] ?? {}; track errorMessage) {
                                                                {{ errorMessage }}<br />
                                                            }
                                                        </div>
                                                    </div>

                                                    <div class="mb-3">
                                                        <label class="form-label">Address Line 2</label>
                                                        <input class="form-control" type="text" formControlName="address_2" autocomplete="billing address-line2" />
                                                    </div>

                                                    <div class="mb-3">
                                                        <label class="form-label">Address Line 2</label>
                                                        <input class="form-control" type="text" formControlName="address_3" autocomplete="billing address-line3" />
                                                    </div>

                                                    <div class="row">
                                                        <div class="col-12 col-xxl-6">
                                                            <div class="mb-3">
                                                                <label class="form-label required">City</label>
                                                                <input class="form-control" type="text" formControlName="city" autocomplete="billing address-level2" [class.is-invalid]="formService.isInvalid(registerForm, 'addresses.correspondence.city')" />
                                                                <div class="invalid-feedback" *ngIf="formService.isInvalid(registerForm, 'addresses.correspondence.city', 'required')">Please provide a locality.</div>

                                                                <div class="invalid-feedback" *ngIf="formService.isInvalid(registerForm, 'addresses.correspondence.city', 'error')">
                                                                    @for (errorMessage of registerForm.get('addresses.correspondence.city')?.errors?.['error'] ?? {}; track errorMessage) {
                                                                        {{ errorMessage }}<br />
                                                                    }
                                                                </div>
                                                            </div>

                                                            <div class="mb-3">
                                                                <label class="form-label">County</label>
                                                                <input class="form-control" type="text" formControlName="county" />
                                                            </div>
                                                        </div>

                                                        <div class="col-12 col-xxl-6">
                                                            <div class="mb-3">
                                                                <label class="form-label required">Country</label>
                                                                <input class="form-control" type="text" formControlName="country" autocomplete="billing country-name" [class.is-invalid]="formService.isInvalid(registerForm, 'addresses.correspondence.country')" />
                                                                <div class="invalid-feedback" *ngIf="formService.isInvalid(registerForm, 'addresses.correspondence.country', 'required')">Please provide a country.</div>

                                                                <div class="invalid-feedback" *ngIf="formService.isInvalid(registerForm, 'addresses.correspondence.country', 'error')">
                                                                    @for (errorMessage of registerForm.get('addresses.correspondence.country')?.errors?.['error'] ?? {}; track errorMessage) {
                                                                        {{ errorMessage }}<br />
                                                                    }
                                                                </div>
                                                            </div>

                                                            <div class="mb-3">
                                                                <label class="form-label required">Postcode</label>
                                                                <input class="form-control" type="text" formControlName="postcode" autocomplete="billing postal-code" [class.is-invalid]="formService.isInvalid(registerForm, 'addresses.correspondence.postcode')" />
                                                                <div class="invalid-feedback" *ngIf="formService.isInvalid(registerForm, 'addresses.correspondence.postcode', 'required')">Please provide a postcode.</div>
                                                                <div class="invalid-feedback" *ngIf="formService.isInvalid(registerForm, 'addresses.correspondence.postcode', 'error')">
                                                                    @for (errorMessage of registerForm.get('addresses.correspondence.postcode')?.errors?.['error'] ?? {}; track errorMessage) {
                                                                        {{ errorMessage }}<br />
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                            </div>
                                         </div>
                                    </div>
                                </div>
                            </div>

                            <div id="integrationDetails" role="tabpanel" class="bs-stepper-pane" [formGroup]="integrations" [ngClass]="{'active' : activeTab === 'integrationDetails'}">
                                <h5 class="mb-1">Accounts</h5>
                                <p class="mb-4">Optionally connect MTA Plus to your preferred accounts system here.</p>

                                <div class="row mb-4">
                                    <div class="col-12 col-md-6 col-lg-4">
                                        <div class="card border shadow-none">
                                            <div class="card-body">
                                                <h5 class="card-title">Xero</h5>
                                                <p class="card-text">Connect to Xero. <a href="#" target="_blank">Read more.</a></p>

                                                <div class="form-check form-switch">
                                                    <input class="form-check-input" type="radio" id="integrations_accounts_xero" formControlName="account_id" [value]="IntegrationTypes.Xero" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12 col-md-6 col-lg-4">
                                        <div class="card border shadow-none">
                                            <div class="card-body">
                                                <h5 class="card-title">Sage</h5>
                                                <p class="card-text">Connect to Sage. <a href="#" target="_blank">Read more.</a></p>

                                                <div class="form-check form-switch">
                                                    <input class="form-check-input" type="radio" id="integrations_accounts_sage" formControlName="account_id" [value]="IntegrationTypes.Sage" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12 col-md-6 col-lg-4">
                                        <div class="card border shadow-none">
                                            <div class="card-body">
                                                <h5 class="card-title">QuickBooks</h5>
                                                <p class="card-text">Connect to QuickBooks. <a href="#" target="_blank">Read more.</a></p>

                                                <div class="form-check form-switch">
                                                    <input class="form-check-input" type="radio" id="integrations_accounts_quickbooks" formControlName="account_id" [value]="IntegrationTypes.QuickBooks" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- <h5 class="mb-1">Marketplaces</h5> -->
                                <!-- <p class="mb-4">Choose one or more from the marketplace integrations below.</p> -->

                                <!-- <div class="row mb-4"> -->
                                <!--     <div class="col-12 col-lg-4"> -->
                                <!--         <div class="card border shadow-none"> -->
                                <!--             <div class="card-body"> -->
                                <!--                 <h5 class="card-title">AutoTrader</h5> -->
                                <!--                 <p class="card-text">Connect to AutoTrader. <a href="#" target="_blank">Read more.</a></p> -->

                                <!--                 <div class="form-check form-switch"> -->
                                <!--                     <input class="form-check-input" type="checkbox" id="integrations_marketplaces_autotrader" /> -->
                                <!--                 </div> -->
                                <!--             </div> -->
                                <!--         </div> -->
                                <!--     </div> -->

                                <!--     <div class="col-12 col-lg-4"> -->
                                <!--         <div class="card border shadow-none"> -->
                                <!--             <div class="card-body"> -->
                                <!--                 <h5 class="card-title">Motors</h5> -->
                                <!--                 <p class="card-text">Connect to Motors. <a href="#" target="_blank">Read more.</a></p> -->

                                <!--                 <div class="form-check form-switch"> -->
                                <!--                     <input class="form-check-input" type="checkbox" id="integrations_marketplaces_motors" /> -->
                                <!--                 </div> -->
                                <!--             </div> -->
                                <!--         </div> -->
                                <!--     </div> -->

                                <!--     <div class="col-12 col-lg-4"> -->
                                <!--         <div class="card border shadow-none"> -->
                                <!--             <div class="card-body"> -->
                                <!--                 <h5 class="card-title">Facebook</h5> -->
                                <!--                 <p class="card-text">Connect to Facebook. <a href="#" target="_blank">Read more.</a></p> -->

                                <!--                 <div class="form-check form-switch"> -->
                                <!--                     <input class="form-check-input" type="checkbox" id="integrations_marketplaces_facebook" /> -->
                                <!--                 </div> -->
                                <!--             </div> -->
                                <!--         </div> -->
                                <!--     </div> -->
                                <!-- </div> -->
                            </div>
                        </div>
                    </div>

                    <div class="card-footer d-flex justify-content-between bg-white">
                        <div>
                            @if (activeTab !== 'userDetails') {
                                <button class="btn btn-outline-primary" (click)="previousTab()"><i class="fas fa-arrow-left"></i> Previous</button>
                            }
                        </div>


                        @if (activeTab === 'integrationDetails') {
                            <button class="btn btn-primary" type="submit" [disabled]="submitting" (click)="onSubmit()">
                                @if (submitting) {
                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    <span role="status">Sign Up</span>
                                } @else {
                                    Sign Up
                                }

                            </button>
                        } @else {
                            <button class="btn btn-primary" [class.disabled]="!currentTabIsValid()" (click)="nextTab()">Next <i class="fas fa-arrow-right"></i></button>
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
