import {Component} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {NgxSpinnerService} from "ngx-spinner";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../../services/auth.service";
import {ToastService} from "../../../shared/services/toast.service";
import {confirmPassword, passwordStrength} from "../../../validators/password-confirmation.validator";
import {HttpErrorResponse} from "@angular/common/http";
import {FormService} from "../../../shared/services/form.service";

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    // styleUrl: './reset-password.component.scss'
    styleUrls: ['../login/login.component.scss', '../../../../assets/scss/circles.scss']
})
export class ResetPasswordComponent {
    public token: string | null;
    public email: string | null;
    public resetPasswordForm: FormGroup = new FormGroup('');

    public constructor(
        public formService: FormService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private fb: FormBuilder,
        private authService: AuthService,
        private toastService: ToastService,
        private spinnerService: NgxSpinnerService
    ) {
        if (this.authService.isAuthenticated()) {
            this.router.navigate(['/app']);
        }

        this.token = this.activatedRoute.snapshot.paramMap.get('token');
        this.activatedRoute.queryParams.subscribe(params => {
            this.email = params?.['email'];
        });
    }

    public ngOnInit(): void {
        this.resetPasswordForm = this.fb.group({
            token: [this.token, Validators.required],
            email: [{value: this.email ?? null, disabled: true}, [Validators.required, Validators.email]],
            password: ['', [Validators.required, passwordStrength()]],
            password_confirmation: ['', [Validators.required]],
        }, {validators: confirmPassword()});
    }

    public onSubmit(): void {
        this.formService.submitted = true;

        if (this.resetPasswordForm.valid) {
            this.resetPasswordForm.disable();
            this.spinnerService.show();

            this.authService.resetPassword(this.resetPasswordForm.value).subscribe({
                next: () => {
                    this.formService.submitted = false;
                    this.spinnerService.hide();
                    this.toastService.success('Password changed successfully.');
                    this.router.navigateByUrl('login');
                },
                error: (response: HttpErrorResponse) => {
                    this.spinnerService.hide();
                    this.resetPasswordForm.enable();
                    this.toastService.error(response.error.message);
                }
            });
        }
    }
}
