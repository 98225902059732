import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Injectable}                                                                            from '@angular/core';
import {BehaviorSubject, lastValueFrom, Observable, switchMap, tap, throwError}                from 'rxjs';
import {catchError, filter, finalize, take}                                                    from 'rxjs/operators';
import {AuthService}                                                                           from '../services/auth.service';
import {StatusCodes}                                                                           from "http-status-codes";

@Injectable({
    providedIn: 'root'
})
export class TokenInterceptor implements HttpInterceptor
{
    private refreshTokenInProgress: boolean = false;
    private refreshTokenSubject: BehaviorSubject<null> = new BehaviorSubject(null);

    constructor(
        private authService: AuthService,
    )
    {
    }

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
    {
        let newRequest = request.clone();
        let url = newRequest.url;

        if ((url.includes('login') || url.includes('register') || url.includes('refresh') || url.includes('email/verify') || url.includes('forgot-password') || url.includes('reset-password')) && !url.includes('integrations')) {
            if (url.includes('refresh')) {
                return next.handle(newRequest);
            }

            return next.handle(request);
        }

        newRequest = request.clone({
            setHeaders: {
                'X-Tenant': `${this.authService.tenant?.id}`
            }
        });

        console.info('Are we authed?', this.authService.isAuthenticated());
        if (this.authService.isAuthenticated()) {
            newRequest = this.addToken(newRequest, this.authService.accessToken);
        }

        return next.handle(newRequest).pipe(
            catchError((requestError: HttpErrorResponse) => {
                if (requestError) {
                    if (requestError.status === StatusCodes.UNAUTHORIZED) {
                        if (this.refreshTokenInProgress) {
                            return this.refreshTokenSubject.pipe(
                                filter((response) => response !== null),
                                take(1),
                                switchMap((response: any) => {
                                    return next.handle(this.addToken(newRequest, response?.data?.token))
                                })
                            );
                        } else {
                            this.refreshTokenInProgress = true;
                            this.refreshTokenSubject.next(null);

                            return this.authService.refresh().pipe(
                                switchMap((response) => {
                                    this.refreshTokenSubject.next(response);
                                    return next.handle(this.addToken(newRequest, response.data.token));
                                }),
                                finalize(() => {
                                    this.refreshTokenInProgress = false
                                })
                            );
                        }
                    }

                    if (requestError.status === StatusCodes.FAILED_DEPENDENCY) {
                        if (requestError.error?.account_redirect_url) {
                            // window.location.href = requestError.error.account_redirect_url;
                        }
                    }
                }

                return throwError(() => requestError);
            })
        );

        // if (this.authService.canRefreshToken()) {
        //     console.log('%c Can refresh token...', 'color: purple;');
        //     return from(this.refreshToken(newRequest, next)).pipe(mergeMap((response: any) => {
        //             return next.handle(this.addToken(newRequest, response.data.token));
        //         }
        //     ));
        // }

        // return next.handle(newRequest).pipe(
        //     catchError((error) => {
        //         if (error.status === 401 && this.authService.canRefreshToken()) {
        //             console.log('%c 401 Error, refreshing token...', 'color: purple;');
        //             return from(this.refreshToken(newRequest, next)).pipe(mergeMap((response) => next.handle(this.addToken(newRequest, response.data.token))));
        //         }

        //         return throwError(() => new Error(error));
        //     })
        // );
    }

    private addToken(request: HttpRequest<any>, token: string): HttpRequest<any>
    {
        return request.clone({
            setHeaders: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    private async refreshToken(request: HttpRequest<any>, next: HttpHandler)
    {
        console.log('%c Starting token refresh...', 'color: purple;');

        const response = await lastValueFrom(this.authService.refresh());
        this.authService.authenticateUser(response);
        return response;

        // return this.authService.loginWithToken().pipe(
        //     switchMap((response) => {
        //         this.authService.authenticateUser(response);
        //         return next.handle(this.addToken(request, this.authService.accessToken));
        //     }),
        //     catchError((error) => {
        //         console.log(error);
        //         return throwError(() => new Error(error));
        //     })
        // );
    }
}
