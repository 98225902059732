import {Injectable, NgModule}                                     from '@angular/core';
import {RouterModule, RouterStateSnapshot, Routes, TitleStrategy} from '@angular/router';
import {LoginComponent}                                           from "./core/pages/login/login.component";
import {ResetPasswordComponent}  from "./core/pages/reset-password/reset-password.component";
import {ForgotPasswordComponent} from "./core/pages/forgot-password/forgot-password.component";
import {ConfirmEmailComponent}   from "./core/pages/confirm-email/confirm-email.component";
import {VerifyEmailComponent}    from "./core/pages/verify-email/verify-email.component";
import {AuthGuard}               from "./core/guards/auth.guard";
import {PermissionGuard}         from "./core/guards/permission.guard";
import {AdminComponent}          from "./features/admin.component";
import {NoAuthGuard}             from "./core/guards/no-auth.guard";
import {RegisterComponent}       from "./core/pages/register/register.component";
import {Title}                               from "@angular/platform-browser";
import {environment}                                              from "../environments/environment";

const routes: Routes = [
    {path: 'register', canActivate: [NoAuthGuard], component: RegisterComponent, data: {title: 'Register'}},
    {path: 'login', canActivate: [NoAuthGuard], component: LoginComponent, data: {title: 'Login'}},
    {
        path: 'forgot-password',
        canActivate: [NoAuthGuard],
        component: ForgotPasswordComponent,
        data: {title: 'Forgot Password'}
    },
    {
        path: 'reset-password/:token',
        canActivate: [NoAuthGuard],
        component: ResetPasswordComponent,
        data: {title: 'Reset Password'}
    },
    {
        path: 'confirm-email',
        canActivate: [NoAuthGuard],
        component: ConfirmEmailComponent,
        data: {title: 'Confirm Email'}
    },
    {
        path: 'verify-email',
        canActivate: [NoAuthGuard],
        component: VerifyEmailComponent,
        data: {title: 'Verify Email'}
    },
    {
        path: 'app',
        canActivate: [AuthGuard],
        canActivateChild: [PermissionGuard],
        component: AdminComponent,
        data: {breadcrumb: 'Home'},
        loadChildren: () => import('./features/admin.module').then(m => m.AdminModule)
    },

    {path: '**', redirectTo: '/login', pathMatch: 'full'}
];

@Injectable()
export class TemplatePageTitleStrategy extends TitleStrategy
{
    constructor(private readonly title: Title)
    {
        super();
    }

    override updateTitle(routerState: RouterStateSnapshot): void
    {
        const title = this.buildTitle(routerState);
        const suffix = !environment.production ? ' 🚧' : '';

        if (title !== undefined) {
            this.title.setTitle(`MTA Plus - ${title}${suffix}`);
        }
    }
}

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: [
        {
            provide: TitleStrategy,
            useClass: TemplatePageTitleStrategy
        }
    ]
})
export class AppRoutingModule
{
}
