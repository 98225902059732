import {Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AuthService} from "../../services/auth.service";
import {ToastService} from "../../../shared/services/toast.service";
import {map, Observable, takeWhile, timer} from "rxjs";

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['../login/login.component.scss', '../../../../assets/scss/circles.scss']
    // styleUrl: './forgot-password.component.scss'
})
export class ForgotPasswordComponent {
    public forgotPasswordForm: FormGroup = new FormGroup('');
    public error: any;
    public timeRemaining$: Observable<number>;

    public constructor(
        private fb: FormBuilder,
        private authService: AuthService,
        private toastService: ToastService,
    ) {
    }

    public ngOnInit(): void {
        this.forgotPasswordForm = this.fb.group({
            email: [null, [Validators.required, Validators.email]],
        });
    }

    public onSubmit(): void {
        if (this.forgotPasswordForm.valid) {
            this.forgotPasswordForm.disable();

            this.authService.forgotPassword(this.forgotPasswordForm.value).subscribe({
                next: () => this.toastService.success('An email has been sent to your address, please check your junk folder.'),
                error: (error) => {
                    console.log(error);
                    this.toastService.success('An email has been sent to your address, please check your junk folder.');
                }
            });

            this.forgotPasswordForm.reset();

            this.timeRemaining$ = timer(0, 1000).pipe(
                map(n => (30 - n) * 1000),
                takeWhile(n => n >= 0),
            );

            setTimeout(() => this.forgotPasswordForm.enable(), 30000);
        }
    }
}
