 <div class="authentication-header">
    <ul class="circles">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
    </ul>
</div>

<div class="login-panel d-flex align-items-center justify-content-center">
    <div class="card p-4 w-25">
        <div class="card-body pt-0">
            <div class="text-center">
                <img alt="" src="assets/images/MTALogoNoBackground-01.png" class="logo" />

                <div class="my-4">
                    <h5 class="card-title">Reset Password</h5>
                    <p class="card-text">Change your password with this form, it should be unique and hard to guess.</p>
                </div>
            </div>

            <form #ngForm="ngForm" (ngSubmit)="onSubmit()" [formGroup]="resetPasswordForm" class="row g-3">
                <div class="mb-3">
                    <label class="form-label">New Password</label>
                    <input id="password" class="form-control" type="password" formControlName="password" [class.is-invalid]="formService.isInvalid(resetPasswordForm, 'password')" />

                    <div class="invalid-feedback" *ngIf="formService.isInvalid(resetPasswordForm, 'password', 'passwordStrength')">
                        <ng-container *ngIf="resetPasswordForm.get('password')?.errors?.['required']">Please enter a new password. <br /></ng-container>
                        <ng-container *ngIf="resetPasswordForm.get('password')?.errors?.['passwordStrength']['uppercase']">{{ resetPasswordForm.get('password')?.errors?.['passwordStrength']['uppercase'] }} <br /></ng-container>
                        <ng-container *ngIf="resetPasswordForm.get('password')?.errors?.['passwordStrength']['lowercase']">{{ resetPasswordForm.get('password')?.errors?.['passwordStrength']['lowercase'] }} <br /></ng-container>
                        <ng-container *ngIf="resetPasswordForm.get('password')?.errors?.['passwordStrength']['numerical']">{{ resetPasswordForm.get('password')?.errors?.['passwordStrength']['numerical'] }} <br /></ng-container>
                        <ng-container *ngIf="resetPasswordForm.get('password')?.errors?.['passwordStrength']['special']">{{ resetPasswordForm.get('password')?.errors?.['passwordStrength']['special'] }} <br /></ng-container>
                        <ng-container *ngIf="resetPasswordForm.get('password')?.errors?.['passwordStrength']['length']">{{ resetPasswordForm.get('password')?.errors?.['passwordStrength']['length'] }}</ng-container>
                    </div>
                </div>

                <div class="mb-3">
                    <label class="form-label">Confirm Password</label>
                    <input id="password_confirmation" class="form-control" type="password" formControlName="password_confirmation" [class.is-invalid]="formService.isInvalid(resetPasswordForm, 'password_confirmation')" />

                    <div class="invalid-feedback" *ngIf="formService.isInvalid(resetPasswordForm, 'password_confirmation', 'passwordsUnconfirmed')">
                        <ng-container *ngIf="resetPasswordForm.errors?.['passwordsUnconfirmed']">Passwords do not match.</ng-container>
                    </div>
                </div>

                <div class="d-grid gap-2">
                    <button type="submit" class="btn btn-primary">Reset Password</button>
                </div>

                <div class="col-12 text-end">
                    <a routerLink="/login">Cancel</a>
                </div>
            </form>
        </div>
    </div>
</div>
