import {Injectable} from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import {PermissionsService} from "../services/permissions.service";

@Injectable({
    providedIn: 'root'
})
export class PermissionGuard {
    constructor(
        private permissionService: PermissionsService,
        private router: Router,
    ) {
    }

    public canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean {
        return this.can(route.data['permission'], state.url);
    }

    public canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean {
        return this.can(childRoute.data['permission'], state.url);
    }

    private can(permission: string, redirectUrl: string): boolean {
        if (permission && !this.permissionService.can(permission)) {
            this.router.navigate(['login'], {queryParams: {redirectUrl}}).then(r => r);
            return false;
        }

        return true;
    }
}
