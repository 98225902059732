import {Component, Inject, Renderer2, ViewEncapsulation} from '@angular/core';
import {DOCUMENT}                                        from "@angular/common";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss',
    'encapsulation': ViewEncapsulation.None
})
export class AppComponent
{
    public constructor(
        @Inject(DOCUMENT) public document: Document,
        private renderer: Renderer2
    )
    {
        const mode: string = window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches ? 'dark' : 'light';

        this.renderer.setAttribute(document.querySelector('html'), 'class', `${mode}-theme`);
        this.renderer.setAttribute(document.querySelector('html'), 'data-bs-theme', mode);
    }
}
