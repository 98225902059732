import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment} from '@angular/router';
import {AuthService} from '../services/auth.service';
import { firstValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class NoAuthGuard {
    constructor(
        private authService: AuthService,
        private router: Router
    ) {
    }

    public async canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<boolean> {
        return this.check();
    }

    public async canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<boolean> {
        return this.check();
    }

    public async canLoad(
        route: Route,
        segments: UrlSegment[]
    ): Promise<boolean> {
        return this.check();
    }

    private async check(): Promise<boolean> {
        console.log('%c NoAuthGuard check...', 'color: orange;');
        if (this.authService.user?.id && !this.authService.isAuthenticated() && this.authService.canRefreshToken()) {
            await firstValueFrom(this.authService.refresh());
        }

        if (this.authService.isAuthenticated()) {
            await this.router.navigate(['/app']);
        }

        return !this.authService.isAuthenticated();
    }
}
