import { Component } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../../services/auth.service";
import {ToastService} from "../../../shared/services/toast.service";
import {HttpErrorResponse} from "@angular/common/http";

@Component({
  selector: 'app-confirm-email',
  templateUrl: './confirm-email.component.html',
  styleUrl: './confirm-email.component.scss'
})
export class ConfirmEmailComponent {
    constructor(
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private authService: AuthService,
        private toastService: ToastService,
	) {
		this.activatedRoute.queryParams.subscribe(params => {
			if (params['url']) {
				this.authService.verifyEmail(decodeURI(params['url'])).subscribe({
					next: (response) => {
						this.toastService.success('Email verified successfully, you can now login.');
						this.router.navigate(['/login']);
					},
					error: (response: HttpErrorResponse) => {
						this.authService.handleError(response);
					}
				});
			}
		});
	}
}
